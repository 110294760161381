.call-out-box {
    display: block;
    float: none;
    width: inherit;
    padding: 8px 16px;
    margin: 8px 4px 0;
}
.benefits-boxes div {
    float: none;
}
.steps div {
    float: none;
    width: inherit;
}
.bottom-btn {
    width: inherit;
}
